<template>
  <transition name="el-fade-in-linear" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: 'Auth'
}
</script>

<style scoped>

</style>
